<template>
	<div>
        {{finalPrice}}
    </div>
</template>

<script>
import Common from '@/assets/js/common.js'

export default {
	name: 'PriceFormat',
	props: {
		Number: [String, Number],
	},
	data() {
        return {
            finalPrice : 0,
        }
    },

	mounted() {
		this.FormatNumber()
	},
	methods: {

        async FormatNumber(){
           this.finalPrice = await this.priceFormat(this.Number)
        },
	},
	watch: {
		Number(){
			this.FormatNumber()
		}
	}
}
</script>
